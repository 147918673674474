.sidebar.open ~ .home-section {
	left: 250px;
	width: calc(100% - 250px);
}
.home-section .text {
	display: inline-block;
	color: #11101d;
	font-size: 25px;
	font-weight: 500;
	margin: 18px;
}
.masonry-brick {
	color: #fff;
	background-color: #8400ff;
	display: inline-block;
	padding: 5px;
	width: 100%;
	text-align: center;
	vertical-align: middle;
	line-height: 90px;
	margin: 1em 0;
	font-size: large;
}
.masonry-container {
	text-align: center;
}

@media only screen and (min-width: 480px) {
	.masonry-container {
		-moz-column-count: 3;
		-webkit-column-count: 3;
		column-count: 3;
	}
}

@media only screen and (min-width: 768px) {
	.masonry-container {
		-moz-column-count: 4;
		-webkit-column-count: 4;
		column-count: 4;
	}
}

@media only screen and (min-width: 960px) {
	.masonry-container {
		-moz-column-count: 5;
		-webkit-column-count: 5;
		column-count: 5;
	}
}
